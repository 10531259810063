import React from 'react';
import { 
  Grid, 
  Typography, 
  Box, 
  useTheme, 
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { Balance, CheckCircleOutline } from '@mui/icons-material';

function ValueProposition() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ 
      backgroundColor: "#faf7f2",
      width: "100%",
      pt: isSmall ? 5 : 10,
      pb: 10
    }} 
      py={matches ? 8 : 4}
      align="center"
    >
      <Grid container width={isMedium ? "75%" : "100%"} align="center" spacing={0} justifyContent="center">
        <Box width="90%">
          <Grid item xs={12} sm={12} md={12} align="center">
            <Balance sx={{ fontSize: 50 }} />
          </Grid>
          <Grid item sx={{ mb: 5 }} xs={12} sm={12} md={12}>
            <Typography variant="h4" align="center">
              The current system is broken.
            </Typography>
          </Grid>
          <Grid item sx={{ mb: 5 }} xs={12} sm={12} md={12}>
            <Typography sx={{ fontWeight: 300 }} variant="h6" textAlign={isSmall ? "left" : "center"}>
              Traditional media is antiquated. Social media breeds controversy and misinformation. We have a better solution.
            </Typography>
          </Grid>
        </Box>
          <Grid sx={{ mb: 3 }} item xs={12} sm={4} md={4} align="center">
            <Box align="center" width="90%" textAlign="left">
              <Typography sx={{fontStyle: "bold"  }} variant="h6">For Readers:</Typography>
              <Typography sx={{ fontWeight: 300 }}>
                We believe that everyone should have access to free-flowing information from trusted sources without their attention being sold to advertisers.
              </Typography>
              <List align="center">
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon>
                    <CheckCircleOutline fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText primary="Quality, factual content" />
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon>
                    <CheckCircleOutline fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText primary="Diverse sources, all in one place" />
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon>
                    <CheckCircleOutline fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText primary="A single, affordable subscription" />
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon>
                    <CheckCircleOutline fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText primary="No distractions, just news" />
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon>
                    <CheckCircleOutline fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText primary="We don't farm your data" />
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Grid sx={{ mb: 3 }} item xs={12} sm={4} md={4} align="center">
            <Box align="center" width="90%" textAlign="left">
              <Typography sx={{fontStyle: "bold"  }} variant="h6">For Writers:</Typography>
              <Typography sx={{ fontWeight: 300 }}>
                We believe that the best journalism comes from the experts closest to the source. If you have something worth sharing, you should have access to the tools necessary to publish your work and get paid.
              </Typography>
              <List align="center">
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon>
                    <CheckCircleOutline fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText primary="Own your work, get paid for it" />
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon>
                    <CheckCircleOutline fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText primary="Instantly expand your audience" />
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon>
                    <CheckCircleOutline fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText primary="Build your reputation" />
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon>
                    <CheckCircleOutline fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText primary="Impact-focused distribution" />
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon>
                    <CheckCircleOutline fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText primary="Access to support & grants" />
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Grid sx={{ mb: 3 }} item xs={12} sm={4} md={4} align="center">
            <Box align="center" width="90%" textAlign="left">
              <Typography sx={{fontStyle: "bold"  }} variant="h6">Moderation & Governance:</Typography>
              <Typography sx={{ fontWeight: 300 }}>
                The Aemula Protocol is committed to full transparency and decentralized control. Anyone can participate in content moderation or platform governance and be compensated for their contributions.
              </Typography>
              <List align="center">
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon>
                    <CheckCircleOutline fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText primary="Censor-Proof: No single entity controls the flow of information" />
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon>
                    <CheckCircleOutline fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText primary="Moderated: Content is reviewed by diverse selections of paid moderators" />
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  <ListItemIcon>
                    <CheckCircleOutline fontSize="small"/>
                  </ListItemIcon>
                  <ListItemText primary="Accountable: Platform-wide reputation tracking to reward quality contributions and limit the reach of bad actors" />
                </ListItem>
              </List>
            </Box>
          </Grid>
      </Grid>
    </Box>
  );
}

export default ValueProposition;