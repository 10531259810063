import React, { useRef } from 'react';
import { CssBaseline, Container } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from './components/Landing';
import Mission from './components/Mission';
import ValueProposition from './components/ValueProposition';
import Subscribe from './components/Subscribe';
import Footer from './components/Footer';
import Footer2 from './components/Footer2';
import BottomNavigationBar from './components/BottomNavigation';
import { Element } from 'react-scroll';

const Unsubscribe = React.lazy(() => import('./components/Unsubscribe.jsx'));
//const Careers = React.lazy(() => import('./components/Careers.jsx'));
const SubscribeLink = React.lazy(() => import('./components/SubscribeLink.jsx'));

function Home() {
  const missionRef = useRef(null);
  const valuePropositionRef = useRef(null);
  const subscribeRef = useRef(null);

  return (
    <>
      <CssBaseline /> {/* Resets browser defaults */}
      <Container maxWidth={false} disableGutters>
        <Element name="landing-section">
          <Landing />
        </Element>
        <div ref={missionRef} id="mission-section">
          <Element name="mission-section">
            <Mission />
          </Element>
        </div>
        <div ref={valuePropositionRef} id="value-proposition-section">
          <Element name="value-proposition-section" >
            <ValueProposition />
          </Element>
        </div>
        <div ref={subscribeRef} id="subscribe-section">
          <Element name="subscribe-section">
            <Subscribe />
          </Element>
        </div>
        <Element name="footer-section">
          <Footer />
        </Element>
        <Element name="footer2-section">
          <Footer2 />
        </Element>
      </Container>
      <BottomNavigationBar sectionRefs={{ missionRef, valuePropositionRef, subscribeRef }}/>
    </>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/subscribe" element={<SubscribeLink />} />
      </Routes>
    </Router>
  );
}

export default App;