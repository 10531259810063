import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#2f2f2f',
    },
    secondary: {
      main: '#1e88e5',
    },
    background: {
      default: '#faf7f2',
      paper: '#2f2f2f',
    },
  },
  components: {
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: "grey",
          "&.Mui-selected": {
            color: "#ffffff",
          }
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        // Targeting the filled style variant
        root: {
          '& .MuiFilledInput-root': {
            backgroundColor: '#faf7f2', // Setting the background color to white for the filled variant
            '&:hover': {
              backgroundColor: '#faf7f2', // Optional: Background color on hover (if you want to keep it white on hover)
            },
            '&.Mui-focused': {
              backgroundColor: '#faf7f2', // Optional: Background color when the text field is focused
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontStyle: "italic",
          fontWeight: 300,
          paddingRight: "3px",
          paddingLeft: "3px"
        }
      }
    }
  }
});

export default Theme;