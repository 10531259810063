import React from 'react';
import { Grid, Typography, Box, useTheme, useMediaQuery } from '@mui/material';

function Footer() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box sx={{ backgroundColor: "#faf7f2", color: "#555555", pt: 5, pb: 5 }} width="100%" py={matches ? 8 : 4}>
      <Grid container sx={{ ml: "5%", mr: "5%" }} width="90%" spacing={0} justifyContent="center">
        <Grid item xs={12} sm={12} md={12} align="center">
          <Typography sx={{ pt: 0 }} variant="h6">
            Aemula
          </Typography>
        </Grid>
        <Grid item sx={{ mt: 2, mb: 1 }} xs={12} sm={12} md={12} align="center">
          <Box sx={{ mb: 0 }} textAlign="center">
            <Typography variant="h6">
              "stimulos dedit aemula virtus"
            </Typography>
          </Box>
          <Box sx={{ mb: 1 }} textAlign="center">
            <Typography sx={{ fontStyle: "italic" }}>
              [rivalry in excellence spurs them on]
            </Typography>
          </Box>
          <Box sx={{ mb: 5 }} textAlign="center">
            <Typography sx={{ fontStyle: "italic" }}>
              - Lucan, Pharsalia (c. 61-65 AD)
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "200", fontStyle: "italic", mb: 0.5 }}>
              Built using:
            </Typography>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 50, height: 50 }}>
                <img
                  alt="Alchemy Logo"
                  src={`alchemy-logo-black.png`}
                  style={{ maxWidth: '85%', maxHeight: '100%', opacity: '0.3' }}
                />
              </Grid>
              <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 50, height: 50 }}>
                <img
                  alt="Optimism Logo"
                  src={`op-logo-red.png`}
                  style={{ maxWidth: '100%', maxHeight: '100%', opacity: '0.3'}}
                />
              </Grid>
              <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 50, height: 50 }}>
                <img
                  alt="The Graph Logo"
                  src={`graph-logo-black.png`}
                  style={{ maxWidth: '100%', maxHeight: '100%', opacity: '0.3' }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;