import React from 'react';
import { Box, Grid, Typography, Button, IconButton, useMediaQuery, useTheme } from '@mui/material';

function Footer2() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')); // Detect small screens

  return (
    <Box sx={{ backgroundColor: '#555555', color: 'white', py: isSmall ? 2 : 3, px: 2, mb: 7 }}>
      <Grid container alignItems="center" spacing={isSmall ? 1 : 3}>
        {/* Left-aligned logo */}
        <Grid item xs={12} sm={4} display="flex" justifyContent={isSmall ? 'center' : 'flex-start'}>
          <Box
            component="img"
            src="/aemulaWhiteTransparent.png"
            alt="logo"
            sx={{ height: isSmall ? 30 : 50, filter: 'brightness(0.85)'}}
          />
        </Grid>

        {/* Center-aligned content */}
        <Grid item xs={12} sm={4} textAlign="center">
          <Typography sx={{ fontStyle: "italic", fontSize: isSmall ? 14 : 16, color: '#d8d8d8' }} gutterBottom>
            New York, New York
          </Typography>
          <Button
            sx={{
              color: "#d8d8d8",
              borderColor: "#d8d8d8",
              fontSize: isSmall ? 8 : 10,
              '&:hover': {
                borderColor: '#d8d8d8',
                backgroundColor: 'rgba(255, 255, 255, 0.1)', // Optional: Add a subtle background on hover
              },
              padding: isSmall ? '4px 8px' : '6px 12px',
            }}
            variant="outlined"
            href="https://aemula.notion.site/Open-Positions-7ab06cddb3e6484d8f76474f400ee82d"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Positions
          </Button>
        </Grid>

        {/* Right-aligned social links */}
        {/* icons from flaticon.com */}
        <Grid item xs={12} sm={4} display="flex" justifyContent={isSmall ? 'center' : 'flex-end'}>
          <IconButton href="https://x.com/AemulaEth" target="_blank" rel="noopener noreferrer" sx={{ color: 'white', p: isSmall ? 0.5 : 1 }}>
            <img src="/twitter-alt.svg" alt="Twitter" style={{ height: isSmall ? 20 : 24, width: isSmall ? 20 : 24 }} />
          </IconButton>
          <IconButton href="https://www.linkedin.com/company/aemula/" target="_blank" rel="noopener noreferrer" sx={{ color: 'white', p: isSmall ? 0.5 : 1 }}>
            <img src="/linkedin.svg" alt="LinkedIn" style={{ height: isSmall ? 20 : 24, width: isSmall ? 20 : 24 }} />
          </IconButton>
          <IconButton href="https://aemula.substack.com" target="_blank" rel="noopener noreferrer" sx={{ color: 'white', p: isSmall ? 0.5 : 1 }}>
            <img src="/substack-icon.svg" alt="Substack" style={{ height: isSmall ? 20 : 24, width: isSmall ? 20 : 24 }} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer2;