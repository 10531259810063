import React from 'react';
import { 
  Grid, 
  Typography, 
  Box, 
  useTheme, 
  useMediaQuery,
  TextField,
  Button,
  Alert,
  Snackbar
} from '@mui/material';
import { PersonAdd } from '@mui/icons-material';

const subscribeAPI = process.env.REACT_APP_SUBSCRIBE_API;

function Subscribe() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [email, setEmail] = React.useState("");
  const [buttonLock, setButtonLock] = React.useState(false);
  const [buttonText, setButtonText] = React.useState("Join");
  const [alert, setAlert] = React.useState({ open: false, message: '', severity: 'success' });

  const handleSubscribe = async () => {
    setButtonLock(true);
    try {
      const response = await fetch(subscribeAPI, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email }),
      });

      const data = await response.json();

      if (response.ok) {
        setAlert({ open: true, message: data.message, severity: 'success' });
        setEmail("");
        setButtonText("Thanks!");
      } else {
        setButtonLock(false);
        let errorMessage = data.message || 'Error subscribing to the email list';
        let severity = 'warning';

        switch (response.status) {
          case 400:
            errorMessage = 'Invalid email format. Please enter a valid email address.';
            break;
          case 409:
            errorMessage = 'You are already subscribed.';
            break;
          default:
            severity = 'error';
            break;
        }

        setAlert({ open: true, message: errorMessage, severity: severity });
      }
    } catch (error) {
      console.error('Error subscribing to email list:', error);
      setAlert({ open: true, message: 'Error subscribing to email list', severity: 'error' });
    }
  };

  return (
    <Box sx={{ backgroundColor: "#555555", color: "white", pt: isSmall ? 2 : 10, pb: 10 }}>
      <Grid item xs={12} sm={12} md={12} align="center">
        <Box sx={{ mt: 3 }} width="90%" textAlign="center">
          <PersonAdd sx={{ fontSize: 50 }} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} align="center">
        <Box sx={{ mb: 3 }} width="90%" textAlign="center">
          <Typography variant="h4">
            Join our growing community!
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} align="center">
        <Box sx={{ mb: 3 }} width="75%" textAlign="center">
          <Typography>
            Gain early access to the live demo.
          </Typography>
          <Typography>
            Stay updated on our progress.
          </Typography>
          <Typography>
            It's free!
          </Typography>
        </Box>
      </Grid>
      <Grid container spacing={0} align="center">
        <Grid item sx={{ mb: 1 }} xs={12} sm={12} md={12}>
          <Typography sx={{ fontWeight: 200, fontStyle: "italic" }} width="90%" textAlign="center">
            unsubscribe at any time
          </Typography>
        </Grid>
        <Grid item sx={{ mb: 3 }} xs={12} sm={12} md={12} align="center">
          <TextField
            id="email-input"
            variant="filled"
            style={{
              width: "75%",
              maxWidth: "400px",
            }}
            rows={1}
            size="small"
            label="Email"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubscribe();
              }
            }}
          />
        </Grid>
        <Grid item sx={{ mb: isSmall ? 3 : 5 }} xs={12} sm={12} md={12} justifyContent="center">
          <Button
            sx={{
              '&.Mui-disabled': {
                color: 'grey',
              }
            }}
            variant="contained" 
            size="medium"
            onClick={handleSubscribe}
            disabled={buttonLock}
          > 
            {buttonText}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography sx={{ mb: 1, fontStyle: "italic"}} width="90%" textAlign="center">
            Interested in joining the team?
          </Typography>
          <Button
            sx={{ 
              color: "white",
              borderColor: "white",
              fontSize: 10,
              '&:hover': {
                borderColor: 'white',
                backgroundColor: 'rgba(255, 255, 255, 0.1)', // Optional: Add a subtle background on hover
              },
            }}
            variant="outlined"
            href="https://aemula.notion.site/Open-Positions-7ab06cddb3e6484d8f76474f400ee82d"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open Positions
          </Button>
        </Grid>
      </Grid>

      <Snackbar 
        open={alert.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={5000} 
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Subscribe;