import React from 'react';
import { Grid, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { KeyboardDoubleArrowDown } from '@mui/icons-material';

function Landing() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isShort = useMediaQuery('(max-height: 700px)');

  return (
    <Box sx={{ 
      backgroundColor: "#faf7f2",
      width: "100%",
      pt: 0
    }} 
      py={matches ? 8 : 4}
    >
      <Grid sx={{ mb: 10 }} marginTop={isSmall && isShort ? 2 : isSmall ? 7 : 0} container spacing={0} align="center">
        <Grid item xs={12} sm={12} md={12}>
          <img
            alt="Aemula Logo"
            style={{
              width: "90%",
              maxWidth: "575px"
            }}
            src={`aemulaLogo.png`}
          />
        </Grid>
        <Grid sx={{ mb: isSmall && isShort ? 1 : isSmall ? 2 : 3 }} item xs={12} sm={12} md={12}>
          <Typography sx={{ width: "70%" }} variant="h4" align="center">
            Expanding worldviews.
          </Typography>
        </Grid>
        <Grid sx={{ pb: 10 }} item xs={12} sm={12} md={12}>
          <Typography sx={{ fontSize: 12, fontWeight: "200", fontStyle: "italic" }}>scroll</Typography>
          <KeyboardDoubleArrowDown sx={{ fontSize: 50, opacity: 0.5 }} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Landing;