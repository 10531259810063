import React, { useEffect } from 'react';
import { scroller } from 'react-scroll';
import { 
    BottomNavigation, 
    BottomNavigationAction,
    Paper,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { Public, Balance, PersonAdd } from '@mui/icons-material';

function BottomNavigationBar({ sectionRefs }) {
    const [value, setValue] = React.useState(-1);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        // Function to update the active section
        const updateActiveSection = (entries) => {
          // Find the entry with the highest intersection ratio
          const highestRatioEntry = entries.reduce((prev, current) => 
            (prev.intersectionRatio > current.intersectionRatio) ? prev : current);
      
          // Only proceed if the highest ratio is greater than 0
          if (highestRatioEntry.intersectionRatio > 0.1) {
            const activeSectionIndex = Object.values(sectionRefs).findIndex(
              ref => ref.current === highestRatioEntry.target);
      
            // Update the active state
            setValue(activeSectionIndex);
          } 
        };
      
        const observer = new IntersectionObserver(updateActiveSection, {
          threshold: isSmall ? 0.10 : 0.75, // Adjust this threshold based on when you consider a section "in view"
          rootMargin: "-5%" // Adjust based on needs
        });
      
        // Start observing each section
        Object.values(sectionRefs).forEach(ref => {
          if (ref.current) observer.observe(ref.current);
        });
      
        // Cleanup
        return () => observer.disconnect();
      }, [sectionRefs, isSmall]); // Ensure sectionRefs is stable to prevent unnecessary re-creation of the observer      

    const handleChange = (event, newValue) => {
        setValue(newValue);
        scrollToSection(newValue);
    };

    const scrollToSection = (sectionIndex) => {
        const sectionNames = ['mission-section', 'value-proposition-section', 'subscribe-section'];
        const sectionName = sectionNames[sectionIndex];
        scroller.scrollTo(sectionName, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutCubic',
            offset: 1
        });
    };

    return (
        <Paper sx={{ 
            position: 'fixed', 
            bottom: 0, 
            left: 0, 
            right: 0,
            pb: 1
        }} 
            elevation={3}
        >
            <BottomNavigation
                showLabels
                value={value}
                onChange={handleChange}
            >
                <BottomNavigationAction 
                    label="Mission" 
                    icon={<Public />}
                />
                <BottomNavigationAction 
                    label="Vision" 
                    icon={<Balance />}
                />
                <BottomNavigationAction 
                    label="Join"
                    icon={<PersonAdd />}
                />
            </BottomNavigation>
        </Paper>
    );
}

export default BottomNavigationBar;