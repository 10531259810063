import React from 'react';
import { Grid, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import { Public, HorizontalRule } from '@mui/icons-material';

function Mission() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ backgroundColor: "#555555", color: "white", pt: isSmall ? 5 : 10, pb: 10 }} py={matches ? 8 : 4}>
      <Grid container spacing={0} justifyContent="center">
        <Grid item  sx={{ p: 0 }} xs={12} sm={12} md={12} align="center">
          <Public sx={{ fontSize: 50 }} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} align="center">
          <Typography sx={{ pt: 0 }} variant="h4" align="center">
            Our Mission
          </Typography>
        </Grid>
        <Grid item sx={{ mt: 3, mb: 5 }} xs={12} sm={12} md={12} align="center">
          <Box sx={{ mb: 2 }} width="90%" textAlign={isSmall ? "center" : "center"}>
            <Typography  variant="h6">
              Aemula is a decentralized platform for independent journalism.
            </Typography>
          </Box>
          <Box sx={{ mb: 2, fontWeight: 300 }} width="90%" maxWidth="600px" textAlign={isSmall ? "center" : "center"}>
            <Typography> 
              One subscription for readers.
            </Typography>
            <Typography>
              Ownership for writers.
            </Typography>
            <Typography>
              Real people sharing real news.
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <HorizontalRule fontSize="large" />
          </Box>
          <Box width="90%" maxWidth="700px" textAlign="center">
            <Typography sx={{ fontStyle: "italic" }}>
              We aim to support and promote the efficient exchange of information and ideas, optimizing humanity's ability to collaborate effectively. 
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Mission;